<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'ShortUrlList',
              }"
            >短網址管理
            </b-breadcrumb-item
            >
            <b-breadcrumb-item active>短網址列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-4 mb-2 font-weight-bold">短網址列表</h4>
        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            class="flex-shrink-0 mb-2 mb-xl-0 mr-3"
            variant="primary"
            v-if="checkPermission([consts.SHORT_URL_CREATE])"
            :to="{ name: 'ShortUrlCreate' }"
          ><i class="fa fa-plus"></i>新增短網址</b-button
          >
          <b-input-group>
            <b-form-input v-model="query.keyword" placeholder="搜尋" v-on:keyup.enter="handlePageReset"></b-form-input>
            <b-input-group-append>
              <b-button @click="handlePageReset"><i class="fa fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="shortUrls"
                :fields="fields"
              >
                <template #cell(code)="data">
                  <b-button
                    v-b-modal="`modal-${data.item.id}`"
                    class="mr-2"
                    variant="inverse-dark"
                    >
                    <i class="fa fa-link mr-0"></i>
                  </b-button>
                  <b-modal :id="`modal-${data.item.id}`">
                    <div class="d-flex flex-column align-items-center">
                      <b-form-input
                          @click="clipboard(`${shortUrlHost}/${data.item.code}`)"
                          @focus="$event.target.select()"
                          :value="`${shortUrlHost}/${data.item.code}`"
                          v-b-tooltip.click
                          title="已複製"
                      >
                      </b-form-input>
                      <div>
                        <vue-qr :text="`${shortUrlHost}/${data.item.code}`"></vue-qr>
                      </div>
                    </div>
                  </b-modal>
                  <a target="_blank" :href="`${shortUrlHost}/${data.item.code}`">{{`${shortUrlHost}/${data.item.code}`}}</a>
                </template>
                <template #cell(url)="data">
                  <div>
                    {{data.item.url_type == 'oa'? '官方帳號': data.item.url && data.item.url.slice(0, 40) + '...'}}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    variant="inverse-primary"
                    :to="{
                      name: 'ShortUrlView',
                      params: { short_url_id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-eye"></span> 查看
                  </b-button>
                  <b-button
                    variant="inverse-warning"
                    :to="{
                      name: 'ShortUrlEdit',
                      params: { short_url_id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-lead-pencil"></span> 編輯
                  </b-button>
                  <b-button
                    variant="inverse-danger"
                    @click="deleteShortUrls(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { format } from "date-fns";
import {mapState} from "vuex";
import shortUrlApi from "@/apis/short-url";
import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts'
import VueQr from 'vue-qr'
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import { paginationMixin } from "@/mixins/pagination";


export default {
  mixins: [paginationMixin],
  components: {
    VueQr,
    CustomPagination,
  },
  data() {
    return {
      consts: consts,
      totalRows: 0,
      showLoading: true,
      shortUrls: [],
      shortUrlHost: process.env.VUE_APP_SHORT_URL_HOST || 'https://wal.tw',
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        keyword: null,
      },
      fields: [
        {
          key: "title",
          label: "標題",
        },
        {
          key: "code",
          label: "短網址",
        },
        {
          key: "url",
          label: "目標網址",
        },
        {
          key: "created_at",
          label: "建立時間",
          formatter: (value) => {
            if (value) {
              return format(new Date(value), 'yyyy-MM-dd');
            }
            return '';
          }
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.getShortUrls();
    },
  },
  created() {
    this.query = updateQueryFromRoute(
      this.$route,
      this.$store,
      this.query
    );
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getShortUrls();
      this.initialized = true;
    },
    async getShortUrls() {
      this.showLoading = true;
      try {
        let params = {
          ...this.query
        };

        const {data} = await shortUrlApi.getShortUrls(this.organization, params);
        this.shortUrls = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得關鍵字資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async deleteShortUrls(shortUrl) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>關鍵字：${shortUrl.code}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await shortUrlApi.removeShortUrl(this.organization, shortUrl);
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 " + shortUrl.name,
              });
              this.getShortUrls();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: '錯誤',
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },

    clipboard(value) {
      navigator.clipboard.writeText(value)
    }
  },
};
</script>

<style scoped>
.copy {
  cursor: pointer;
}

.qrcode {
  cursor: pointer;
}

.btn + .btn {
  margin-left: 0.5rem;
}

.vdp-datepicker {
  min-width: 15rem;
}
</style>
